<!--
  报表/仪表板访问权限
-->
<template>
  <div class="access_permissions">
    <div
      class="permissions_title01"
      v-if="types === $t('label.visualpage.btype')"
    >
      {{ $t("label.foldervisitlimit") }}
    </div>
    <!-- 仪表板访问权限 -->
    <div class="permissions_title01" v-else>
      {{ $t("vue_label_dashboard_dashboardaccess") }}
    </div>
    <div class="permissions_input">
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <el-form-item :label="$t('label.usableshareuser')">
          <el-select
            v-model="form.user"
            :placeholder="$t('label.foldervisitlimit3')"
          >
            <el-option
              :label="$t('label.foldervisitlimit1')"
              value="0"
            ></el-option>
            <el-option
              :label="$t('label.foldervisitlimit2')"
              value="1"
            ></el-option>
            <el-option
              :label="$t('label.foldervisitlimit3')"
              value="2"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('vue_label_dashboard_range')">
          <el-select
            v-model="form.role"
            placeholder
            :disabled="form.user === '0' || form.user === '1'"
            @change="changeRole"
          >
            <el-option
              v-for="(item, index) in roles"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="permissions_search">
      <el-input
        v-model="form.keyword2"
        :placeholder="$t('label.placeholder')"
        :disabled="form.user === '0' || form.user === '1'"
        @input="searchKeyWord"
      ></el-input>
      <div>{{ $t("label.shareusers") }}</div>
    </div>
    <div class="border_content">
      <!-- 可用共享者 -->
      <div class="left_info">
        <div
          class="each_info"
          v-for="(item, index) in allRoles"
          :key="index"
          @click="tabCurArr = item"
          :class="{ active: tabCurArr.id === item.id }"
        >
          {{ item.name }}
        </div>
      </div>
      <!-- 操作按钮：左右按钮禁用规则同在文件夹中选择报表、仪表板规则 -->
      <div class="middle_butts">
        <div>{{ $t("label.add") }}</div>
        <el-button
          size="mini"
          @click="addToRight"
          :disabled="
            JSON.stringify(tabCurArr) === '{}' ||
            allSelectedRoles.some((item) => item.id === tabCurArr.id)
          "
          >>></el-button
        >
        <el-button
          size="mini"
          @click="addToLeft"
          :disabled="
            JSON.stringify(tabCurArr) === '{}' ||
            allRoles.some((item) => item.id === tabCurArr.id)
          "
          >&lt;&lt;</el-button
        >
        <div>{{ $t("button_partner_delete") }}</div>
      </div>
      <!-- 共享用户 -->
      <div class="right_infos">
        <div
          class="each_info"
          v-for="(item, index) in allSelectedRoles"
          :key="index"
          @click="tabCurArr = item"
          :class="{ active: tabCurArr.id === item.id }"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 类型：报表、仪表板
    types: {
      type: String,
      default: "",
    },
    permissionInfo: {
      // 可用共享者列表和共享用户列表以及选择的可用共享者
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        user: "", // 可用共享者
        role: "", // 选择的角色范围，默认设置为用户
        keyword2: "", // 对共享者的搜索字段
      },
      roles: [
        // 权限范围
        {
          label: this.$i18n.t("label_tabpage_rolez"),
          value: "0",
        },
        {
          label: this.$i18n.t("label.report.share.roleandsub"),
          value: "1",
        },
        {
          label: this.$i18n.t("label.document.history.user"),
          value: "2",
        },
        {
          label: this.$i18n.t("label.publicgroup"),
          value: "3",
        },
        {
          label: this.$i18n.t("label.partneruser"),
          value: "4",
        },
        {
          label: this.$i18n.t("label.roleofpartner"),
          value: "5",
        },
      ],
      allRoles: [], // 所有共享者列表
      allSelectedRoles: [], // 所有已选择的共享者列表
      allRolesSearch: [], // 所有共享者列表，用来搜索，始终是全部
      tabCurArr: {}, // 选中的角色
    };
  },
  watch: {
    permissionInfo: function () {
      this.form.user = this.permissionInfo.user;
      this.allSelectedRoles = this.permissionInfo.allSelectedRoles;
      if(this.allSelectedRoles.length>0){
        let index=this.allSelectedRoles.length-1;
        let lastData=this.allSelectedRoles[index].id;
        // 范围取值根据所选共享用户的最后一个值来判断
        // role角色 0
        // RoleAndSub-角色及内部下属1
        // user用户 2
        // group公用小组 3
        // partneruser 合作伙伴用户4
        // partnerrole合作伙伴角色 5
        if(lastData.indexOf('partneruser-')>-1){
          this.form.role ='4';
        }else if(lastData.indexOf('partnerrole-')>-1){
          this.form.role ='5';
        }else if(lastData.indexOf('RoleAndSub-')>-1){
          this.form.role ='1';
        }else if(lastData.indexOf('user-')>-1){
          this.form.role ='2';
        }else if(lastData.indexOf('group-')>-1){
          this.form.role ='3';
        }else if(lastData.indexOf('role-')>-1){
          this.form.role ='0';
        }else{
          this.form.role ='2';
        }
      }else{
        this.form.role ='2';
      }
    },
    // 监听选择的可用共享者user
    "form.user": function () {
      if (this.form.user === "2") {
        // 只有下列用户可访问此文件夹
        this.changeRole(this.form.role);
      }
    },
  },
  methods: {
    // 切换角色
    changeRole(value) {
      switch (value) {
        case "0": // 角色
          this.allRoles = this.permissionInfo.roleList.filter((item) => {
            return (
              this.allSelectedRoles.every((item1) => item1.id !== item.id) ===
              true
            );
          });
          break;
        case "1": // 角色及内部下属
          this.allRoles = this.permissionInfo.roleAndSubList.filter((item) => {
            return (
              this.allSelectedRoles.every((item1) => item1.id !== item.id) ===
              true
            );
          });
          break;
        case "2": // 用户
          this.allRoles = this.permissionInfo.userList.filter((item) => {
            return (
              this.allSelectedRoles.every((item1) => item1.id !== item.id) ===
              true
            );
          });
          break;
        case "3": // 公用小组
          this.allRoles = this.permissionInfo.groupList.filter((item) => {
            return (
              this.allSelectedRoles.every((item1) => item1.id !== item.id) ===
              true
            );
          });
          break;
        case "4": // 合作伙伴用户
          this.allRoles = this.permissionInfo.partnerUserList.filter((item) => {
            return (
              this.allSelectedRoles.every((item1) => item1.id !== item.id) ===
              true
            );
          });
          break;
        case "5": // 合作伙伴角色
          this.allRoles = this.permissionInfo.partnerRoleList.filter((item) => {
            return (
              this.allSelectedRoles.every((item1) => item1.id !== item.id) ===
              true
            );
          });
          break;
      }
      this.allRolesSearch = JSON.parse(JSON.stringify(this.allRoles)); // 深拷贝共享者列表集合
    },
    // 搜索关键字
    searchKeyWord() {
      this.allRoles = this.allRolesSearch.filter(
        (item) => item.name.indexOf(this.form.keyword2) > -1
      );
    },
    // 添加进此共享用户
    addToRight() {
      // 添加至右侧，从左侧移除
      this.allSelectedRoles.push(this.tabCurArr);
      let index = this.allRoles.findIndex(
        (item) => item.id === this.tabCurArr.id
      );
      this.allRoles.splice(index, 1);
      this.tabCurArr = {}; // 清空选中项
    },
    // 从共享用户中移除
    addToLeft() {
      // 添加至左侧，从右侧移除
      this.allRoles.push(this.tabCurArr);
      let index = this.allSelectedRoles.findIndex(
        (item) => item.id === this.tabCurArr.id
      );
      this.allSelectedRoles.splice(index, 1);
      this.tabCurArr = {}; // 清空选中项
    },
  },
};
</script>

<style lang="scss" scoped>
.access_permissions {
  .permissions_title01 {
    margin: 10px 0;
    color: #080707;
  }
  .permissions_input {
    ::v-deep .el-form {
      display: flex;
      .el-form-item {
        margin-bottom: 6px;
        display: flex;
        flex-direction: column;
      }
      .el-form-item:first-child {
        margin-right: 69px;
        .el-input {
          width: 261px;
        }
      }
      .el-form-item:last-child {
        .el-input {
          width: 120px;
        }
      }
    }
  }
  .permissions_search {
    margin-bottom: 10px;
    display: flex;
    align-items: flex-end;
    color: #333333;
    .el-input {
      width: 261px;
      margin-right: 44px;
    }
  }
}
</style>