<!--
  添加公用报表/仪表板至此文件夹
-->
<template>
  <div class="public_report">
    <div class="report_title01" v-if="types === $t('label.visualpage.btype')">
      {{ $t("vue_label_report_addpublicreportstofolder") }}
    </div>
    <div class="report_title01" v-else>
      {{ $t("vue_label_dashboard_thisfolder") }}
    </div>
    <div class="report_title02" v-if="types === $t('label.visualpage.btype')">
      {{ $t("label.notfiledpublicreport") }}
    </div>
    <div class="report_title02" v-else>
      {{ $t("label.publicdashboard") }}
    </div>
    <div class="report_title03">
      <el-input
        v-model="form.keyword1"
        :placeholder="$t('label.placeholder')"
        id="search"
        @input="searchKeyWord"
      ></el-input>
      <div v-if="types === $t('label.visualpage.btype')">
        {{ $t("label.reportinthisfolder") }}
      </div>
      <div v-else>{{ $t("label.dashboard.this.folder") }}</div>
    </div>
    <div class="border_content">
      <!-- 未归档公用仪表板 -->
      <div class="left_info">
        <div
          class="each_info"
          v-for="(item, index) in dashboardList"
          :key="index"
          @click="tabCurArr = item"
          :class="{ active: tabCurArr.id === item.id }"
        >
          {{ item.name }}
        </div>
      </div>
      <!-- 
        操作按钮：左右操作按钮禁用有以下情况：
          1.未选中item；
          2.在左侧选中的item往左侧添加的按钮禁用；同理，在右侧选中的item往右侧添加的按钮禁用。
       -->
      <div class="middle_butts">
        <div>{{ $t("label.add") }}</div>
        <el-button
          size="mini"
          @click="addToRight"
          :disabled="
            JSON.stringify(tabCurArr) === '{}' ||
            selectDashboardList.some((item) => item.id === tabCurArr.id)
          "
          >>></el-button
        >
        <el-button
          size="mini"
          @click="addToLeft"
          :disabled="
            JSON.stringify(tabCurArr) === '{}' ||
            dashboardList.some((item) => item.id === tabCurArr.id)
          "
          >&lt;&lt;</el-button
        >
        <div>{{ $t("button_partner_delete") }}</div>
      </div>
      <!-- 此文件夹中的仪表板 -->
      <div class="right_infos">
        <div
          class="each_info"
          v-for="(item, index) in selectDashboardList"
          :key="index"
          @click="tabCurArr = item"
          :class="{ active: tabCurArr.id === item.id }"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 类型：报表、仪表板
    types: {
      type: String,
      default: "",
    },
    dashboardInfo: {
      // 未归档公用仪表板和此文件夹中的仪表板
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        keyword1: "",
      },
      tabCurArr: {}, // 选中的仪表板
      dashboardList: [], // 未归档公用仪表板
      selectDashboardList: [], // 此文件夹中的仪表板
    };
  },
  watch: {
    dashboardInfo: {
      handler() {
        // 未归档公用仪表板
        this.dashboardList = this.dashboardInfo.dashboardList;
        // 此文件夹中的仪表板
        this.selectDashboardList = this.dashboardInfo.selectDashboardList;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // 搜索关键字
    searchKeyWord() {
      this.dashboardList = this.dashboardInfo.dashboardList.filter(
        (item) => item.name.indexOf(this.form.keyword1) > -1
      );
    },
    // 添加进此文件夹
    addToRight() {
      // 添加至右侧，从左侧移除
      this.selectDashboardList.push(this.tabCurArr);
      let index = this.dashboardList.findIndex(
        (item) => item.id === this.tabCurArr.id
      );
      this.dashboardList.splice(index, 1);
      this.tabCurArr = {}; // 清空tabCurArr
    },
    // 从文件夹中移除
    addToLeft() {
      // 添加至左侧，从右侧移除
      this.dashboardList.push(this.tabCurArr);
      let index = this.selectDashboardList.findIndex(
        (item) => item.id === this.tabCurArr.id
      );
      this.selectDashboardList.splice(index, 1);
      this.tabCurArr = {}; // 清空tabCurArr
    },
  },
};
</script>

<style lang="scss" scoped>
.public_report {
  .report_title01 {
    margin-bottom: 8px;
    color: #080707;
    margin-top: 7px;
  }
  .report_title02 {
    margin-bottom: 8px;
    color: #333333;
  }
  .report_title03 {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    color: #333333;
    ::v-deep .el-input {
      width: 261px;
      margin-right: 44px;
    }
  }
}
</style>